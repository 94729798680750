
import Vue, { PropType } from 'vue';
import { LocButton, LocLazy } from '@localazy/components';
import LineZet from '@/assets/svg/scribbles/LineZet.vue';
import AutomatedBg from '@/assets/svg/tools/AutomatedBg.vue';
import SvgTopographyLines from '@/assets/svg/SvgTopographyLines.vue';
import TranslatorBadge from '@/assets/svg/badges/TranslatorBadge.vue';

export default Vue.extend({
  name: 'AutopilotFeatured',
  components: {
    LocButton,
    LocLazy,
    LineZet,
    AutomatedBg,
    SvgTopographyLines,
    TranslatorBadge
  },
  props: {
    badges: {
      type: Boolean,
      default: false
    },
    imagePosition: {
      type: String as PropType<'left' | 'right'>,
      default: 'left',
      validator: (value: 'left' | 'right'): boolean => ['left', 'right'].includes(value)
    }
  }
});
