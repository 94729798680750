var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'flex flex-col gap-x-12 gap-y-6',
    _vm.imagePosition === 'left' ? 'md:flex-row' : 'md:flex-row-reverse'
  ],attrs:{"id":"autopilot-featured"}},[_c('div',{staticClass:"flex-1 bg-accent-lighten-4 rounded border-2 border-accent-lighten-4 overflow-hidden min-h-64 md:min-h-80 lg:min-h-96 py-6 lg:px-6 flex items-center justify-center relative"},[_c('loc-lazy',{staticClass:"w-full"},[_c('automated-bg',{staticClass:"w-full h-full overflow-visible relative z-10",attrs:{"badges":_vm.badges}})],1),_vm._v(" "),_c('svg-topography-lines',{staticClass:"absolute top-0 left-0 w-full z-0"})],1),_vm._v(" "),_c('div',{class:[
      'w-full md:max-w-500 flex-shrink-0',
      _vm.imagePosition === 'left' ? 'md:pl-6' : 'md:pr-6'
    ]},[_c('h2',{staticClass:"title leading-tight relative"},[_vm._t("heading",function(){return [_vm._v("\n        Human Quality,"),_c('br'),_vm._v(" "),_c('span',{staticClass:"relative"},[_vm._v("\n          Automated\n          "),_c('line-zet',{staticClass:"absolute bottom-0 left-0 z-20 w-full -mb-3 stroke-primary stroke-3"})],1)]}),_vm._v(" "),(_vm.badges)?_c('translator-badge',{class:[
          'badge absolute w-16 sm:w-20 lg:w-24 -top-1 -right-2',
          _vm.imagePosition === 'right' && 'badge-right'
        ]}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"mt-4 lg:mt-6 text-lg lg:text-xl"},[_vm._t("description",function(){return [_c('p',{staticClass:"mb-2"},[_vm._v("\n          Get translations on autopilot. Our verified professionals will translate your project automatically as you add or change texts.\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          Achieve high-quality results with less work needed on your side.\n        ")])]})],2),_vm._v(" "),_c('div',{staticClass:"mt-6"},[_c('loc-button',{attrs:{"to":"/features/continuous-localization-team","large":"","secondary":""}},[_vm._v("\n        Meet your translation team\n      ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }